import { } from "googlemaps";
import * as $ from 'jquery';

const mapsapikey = "AIzaSyBA-3HMmiI5WK9o3k6jB1I5j7_jX1ZV-uY";

export class GeocodeButton {
    $targetfield: JQuery;

    constructor($searchbutton: JQuery, $targetfield: JQuery) {
        this.$targetfield = $targetfield;
        const self = this;

        $searchbutton.click(function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (pos) => { self.success(pos); },
                    () => { self.error() });
            }
        })
    }

    success(position) {
        const self = this;
        //console.log("Got position", position.coords);
        var latlng = { lat: position.coords.latitude, lng: position.coords.longitude };
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ 'location': latlng }, function (results, status) {
            if (status === 'OK') {
                if (results[0]) {
                    self.$targetfield.val(results[0].formatted_address);
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });

    }

    error() {
        window.alert("We are unable to lookup your position.");
    }
}